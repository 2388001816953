import * as Sentry from "@sentry/browser";

import * as config from '../../webpack/main.config';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  debug: false,
  enabled: process.env.NODE_ENV !== 'development',
  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  release: config.revision,
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  environment: process.env.APP_ENV,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
});