const path = require("path");
const revArr = (process.env.GIT_REVISION || '').split('-');
const revision = revArr[revArr.length - 1];
module.exports = {
  entry: "./src/index.tsx",
  distPath: path.resolve(__dirname, "..", "dist"),
  extractStylesFilename: "styles.css",
  devServerPort: 8080,
  devServerHost: "0.0.0.0",
  publicPath: `/build/${revision}/`,
  revision
};
